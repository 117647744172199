.ce-header, .ce-paragraph, .cdx-list__item, .cdx-input, .tc-table__inp{
  color: #4E6678
}

h1 {
  font-size: 20px;
}
h2 {
  font-size: 18px;
}
h3 {
  font-size: 16px;
}
h4 {
  font-size: 14px;
}
h5 {
  font-size: 12px;
}
h6 {
  font-size: 10px;
}
.ce-paragraph, li, .tc-table__inp {
font-size: 12.8px;
}